@import '../node_modules/antd/dist/antd.css';

@font-face {
  font-family: 'Oxygen-bold';
  src: url('fonts/Oxygen-Bold.eot');
  src: url('fonts/Oxygen-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Oxygen-Bold.woff2') format('woff2'),
    url('fonts/Oxygen-Bold.woff') format('woff'),
    url('fonts/Oxygen-Bold.svg#Oxygen-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen-regular';
  src: url('fonts/Oxygen-Regular.eot');
  src: url('fonts/Oxygen-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Oxygen-Regular.woff2') format('woff2'),
    url('fonts/Oxygen-Regular.woff') format('woff'),
    url('fonts/Oxygen-Regular.svg#Oxygen-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Globals */
html * {
  font-family: Oxygen-regular, Oxygen-bold;
}


.App {
  text-align: center;
}
.Layout {
  width: 100%;
  height: 900px;
  margin-right: auto;
  margin-left: auto;
}

.logo {
  width: 120px;
  height: 31px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px 24px 16px 68px;
  float: left;
}

/* Ant customization */
/* .ant-layout-header {
  height: 84px;
  background: #fff;
  z-index: 5 !important;
}

.ant-layout-header .ant-menu {
  line-height: 5;
} */

/* .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #fff;
} */

/* Side pane */

/* Sliding */
.hide {
  opacity: 0;
  right: -100%;
}
.show {
  opacity: 1;
  right: 0;
}

/* Footer */
.ant-layout-footer {
  background: #fff;
}

/* additional info form */
input.half-width {
  width: 47%;
}
select.half-width {
  width: 47%;
}


/* .submenubar .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.19);
} */
/* .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: none;
} */



.ytp-chrome-top, .ytp-chrome-bottom {
  display: none;
  visibility: hidden;
}

.rs-steps-item-description {
  color: #1abd50;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper{
  color: #1abd50;
}
.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: #1abd50;
  border-color: var(--rs-progress-bar-success);
}
.rs-steps-item-title {
  color: white;
}

.css-yafthl-MuiSlider-markLabel {
  color: #1abd50 !important;
}

.css-1eoe787-MuiSlider-markLabel {
  color: #1abd50 !important;
}

.css-1gv0vcd-MuiSlider-track {
  border: 1px solid #1abd50;
  color: #1abd50;
}

.MuiSlider-track {
  border: 1px solid #1abd50;
  color: #1abd50;
}

.css-eg0mwd-MuiSlider-thumb {
  color: #1abd50;
}

.MuiSlider-thumb {
  color: #1abd50;
}

.css-14pt78w-MuiSlider-rail {
 color: #1abd50;
}

.MuiSlider-rail {
  color: #1abd50;
}

.MuiSlider-markLabel {
  color: #1abd50;
}

.MuiSlider-markLabelActive {
  color: #1abd50;
}

.css-sxo8tj {
  color: #1abd50 !important;
}
.css-60f6gz {
  color: #1abd50 !important;
}
.ant-cascader-menus {
  width: 500px;
}

.style_signupContentText__iv7YR form label {
  color:white !important;
}

.styles_loginFormContent__19rlF form label {
  color: white !important;
}

.styles_loginFormContent__2MLCf form label {
  color:white !important;
}
.style_signupContentText__14_M0 form label {
  color:white !important;
}

