@import '../../styles/colors.module';
@import '../../styles/base.module';

.dashboard {
  padding-top: 84px;
  background: var(--background);
  height: calc(100vh - 84px);
}
.spinnerStyle{
  @include display-flex();
  justify-content: center;
  align-items: center;
}