$bold: 'Oxygen-bold',sans-serif;
$regular: 'Oxygen-regular',sans-serif;
$semiBold: 'Oxygen-Light',sans-serif;
$PoppinsBlackItalic: 'Poppins-BlackItalic',sans-serif;
$PoppinsBold: 'Poppins-Bold',sans-serif;
$PoppinsBoldItalic: 'Poppins-BoldItalic',sans-serif;
$PoppinsExtraBold: 'Poppins-ExtraBold',sans-serif;
$PoppinsExtraBoldItalic: 'Poppins-ExtraBoldItalic',sans-serif;
$PoppinsExtraLight: 'Poppins-ExtraLight',sans-serif;
$PoppinsExtraLightItalic: 'Poppins-ExtraLightItalic',sans-serif;
$PoppinsItalic: 'Poppins-Italic',sans-serif;
$PoppinsLight: 'Poppins-Light',sans-serif;
$PoppinsLightItalic: 'Poppins-LightItalic',sans-serif;
$PoppinsMedium: 'Poppins-Medium',sans-serif;
$PoppinsMediumItalic: 'Poppins-MediumItalic',sans-serif;
$PoppinsRegular: 'Poppins-Regular',sans-serif;
$PoppinsSemiBold: 'Poppins-SemiBold',sans-serif;
$PoppinsSemiBoldItalic: 'Poppins-SemiBoldItalic',sans-serif;
$PoppinsThin: 'Poppins-Thin',sans-serif;
$PoppinsThinItalic: 'Poppins-ThinItalic',sans-serif;
$PoppinsBlack: 'Poppins-Black',sans-serif;


@font-face {
    font-family: "Oxygen-bold";
    src: url("../fonts/Oxygen-Bold.eot");
    src: url("../fonts/Oxygen-Bold.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Oxygen-Bold.woff2") format("woff2"),
      url("../fonts/Oxygen-Bold.woff") format("woff"),
      url("../fonts/Oxygen-Bold.svg#Oxygen-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Oxygen-Light";
    src: url("../fonts/Oxygen-Light.ttf");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Oxygen-regular";
    src: url("../fonts/Oxygen-Regular.eot");
    src: url("../fonts/Oxygen-Regular.eot?#iefix") format("embedded-opentype"),
      url("../fonts/Oxygen-Regular.woff2") format("woff2"),
      url("../fonts/Oxygen-Regular.woff") format("woff"),
      url("../fonts/Oxygen-Regular.svg#Oxygen-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
  }

    
  @font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/Poppins-Black.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/Poppins-BlackItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/Poppins-BoldItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins-Bold.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/Poppins-ExtraBold.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/Poppins-ExtraBoldItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/Poppins-ExtraLight.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url("../fonts/Poppins-ExtraLightItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/Poppins-Italic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/Poppins-Light.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-LightItalic";
    src: url("../fonts/Poppins-LightItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins-Medium.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/Poppins-MediumItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url("../fonts/Poppins-SemiBoldItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Thin";
    src: url("../fonts/Poppins-Thin.ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-ThinItalic";
    src: url("../fonts/Poppins-ThinItalic.ttf");
    font-weight: normal;
    font-style: normal;
  }

%common-input-value {
    border: none;
    width: 100%;
    outline: none;
    font-size: 14px;
    color: #484848;
    opacity: 0.7;
    font-family: 'CircularStd-Book', sans-serif;
}
%skeleton-list {
    height: 50px;/*page height*/
    /* change height to see repeat-y behavior */
    background-image: linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%),
    linear-gradient( #ececec 30px, transparent 0); /* 30px height of bar*/
    background-repeat: repeat-y;
}
@mixin display-flex() {
    display:flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
}
@mixin inline-flex() {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
@mixin align-self() {
    -ms-align-self: center;
    align-self: center;
    -webkit-align-self: center;
}
@mixin align-self1() {
    -ms-align-self: flex-end;
    align-self: flex-end;
    -webkit-align-self: flex-end;
}
@mixin align-self2() {
    -ms-align-self: flex-start;
    align-self: flex-start;
    -webkit-align-self: flex-start;
}
@mixin overflow() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}
@mixin display-block() {
  display:block;
}
