@import '../../styles/colors.module';
@import '../../styles/base.module';

.freeTrialCardContainer {
padding-top: 100px;
min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
.freeTrialCard {
  overflow: hidden;
  .paymentCardContent {
    width: 450px;
    height: 240px;
    border: solid 2.5px var(--tdborder) !important;
    background: var(--tbodytd) !important;
    border-radius: 20px !important;
    position: relative;
    box-shadow: none !important;
    padding: 30px;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // flex-direction: row;
  }
  .membershipBtn {
    position: absolute;
    bottom: -2px;
    background: #1abd50 !important;
    color: $white !important;
    font-weight: bold;
    font-size: 20px;
    left: -2px;
    right: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 450px;
    height: 55px;
    outline: 0;
    padding: 9.5px 20px 14px;
    margin: 0;
    border: 1px solid #1abd50;
    text-align: center;
    cursor: pointer;
  }
  h6 {
    font-size: 14px;
    color: var(--whitecolor);
    margin-top: 20px;
  }
  p.cardTitle {
    font-size: 24px;
    margin-top: -10px;
    font-family: $bold;
    line-height: 1.67;
    margin-bottom: 0px;
    color: var(--whitecolor);
  }
  p.cardSubTitle {
    font-size: 20px;
    padding-top: 0px;
    font-family: $bold;
    line-height: 1.67;
    margin-bottom: 0px;
    color: var(--whitecolor);
  }

  .amount {
    font-family: $bold;
    font-size: 20px;
    letter-spacing: normal;
    color: #1abd50;
    align-self: center;
    margin-top: 20px;
  }
}
}