@import '../../styles/colors.module';
@import '../../styles/base.module';

html {
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
	scroll-snap-points-y: repeat(100vh);
	scroll-snap-type: y mandatory;
}
.homePageContainer {
  // margin-top: 100px;
  background-color:$white;;
  // padding-top: 64px;
}
.homeHeader {
  position: absolute;
  width: 100%;
  top: 10px;
  z-index: 100;
}
.headerContentBox {
  //@include display-flex();
  //flex-wrap: wrap;
  //padding: 20px 55px;
}
.logoHeader {
  img {
    //margin-left: 100px;
    //margin-top: 40px;
    height: 80px;
    width: 350px;
  }
}
.navLinks {
  a {
    color: black;
  }
}
.headerlink {
  width: 100% ;
  @include align-self();
  .menuLinkHome {
    list-style: none;
    //max-width: 600px;
    margin: 15px 100px auto auto;
    @include display-flex();
    // justify-content: space-around;
    justify-content: flex-end;
    li {
      float: left;
      padding-left: 30px;
      @include align-self();
    }
    a {
      font-family: $regular;
      font-size: 18px;
      color: $white;
      outline: 0;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
    color: #fff;
    height: 40px;
    border: 1px solid #fff !important;
    border-radius: 10px;
    cursor: pointer;
    outline: 0;
    font-size: 18px;
    width: 100px;
    }
  }
}
.mobileMenuHome {
  display: none;
  opacity: 0;
}
.headerStaticMainBar {
  position: fixed;
  width: 100%;
  z-index: 1;
  box-shadow: var(--headershadow);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #414a4c !important;
}
.firstLine {
  margin-top: 100px;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
}
.firstParagraph {
  width: 80%;
  text-align: left;
  font-size: 25px;
  margin: auto auto 20px;
}
.bannerVideo {
  width: 100%;
  height: 100%;
  background-color: $black1;
  position: relative;
  padding-top: 100px;
  min-height: 550px;
  height: 100vh;
  video {
    object-fit: fill;
  }
  &::before {
     content: '';
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     //background: linear-gradient(180deg, rgba(29, 27, 27, 0.0224) 0%, rgba(3, 3, 3, 0.28373) 54.48%, rgba(0, 0, 0, 0.32) 100%);
  }
  .playCircle {
    position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      margin: auto;
      text-align: center;
      cursor: pointer;
    // background: #0089ff6b;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      margin: auto;
      text-align: center;
    }
  }
}
.homeBanner {
  // height: calc(100vh - 84px);
  background: #0E1118;
  position: relative;
  height: 100vh;
  &::before {
    content: '';
    position: absolute;
    //background: linear-gradient(180deg, rgba(29, 27, 27, 0.0224) 0%, rgba(3, 3, 3, 0.28373) 54.48%, rgba(0, 0, 0, 0.32) 100%);
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
  }
  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .homeBannerText {
     position: absolute;
     top: 10%;
     left: 0;
     right: 0;
     text-align: center;
     padding: 5%;
     h2 {
      font-size: 64px;
      line-height: 80px;
      color: #F7F7F7;
      font-family: $bold;
     }
  }
  .muteImage {
    position: absolute;
     height: 40px;
     right: 5%;
     bottom: 50px;
  }
}
.whyThinkSabio {
  height: 100vh;
}
.whyThinkSabioContent {
  @include display-flex();
  //position: relative;
  height: 100%;
  .whyThinkSabioText {
    width: 55%;
    margin-top: 30px;
    @include align-self();
    padding: 30px 30px 30px 80px;
    h2 {
      //font-size: 56px;
      font-size: 2rem;
      color: #161614;
      font-family: $bold;
    }
    h3 {
      //font-size: 56px;
      font-size: 1.5rem;
      color: #161614;
      font-family: $bold;
    }
    h4 {
      //font-size: 56px;
      font-size: 1.2rem;
      color: #161614;
      font-family: $bold;
    }
    li {
      font-size: 1.1rem;
      color: #161614;
    }
    p {
      font-family: $regular;
      font-size: 18px;
      color: #161614;
      line-height: 28px;
      margin-bottom: 40px;
    }
    button {
      color: #259745;
      height: 45px;
      width: 150px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #259745 !important;
      font-size: 20px;
      outline: 0;
      font-family: $regular;
      background: transparent;
    }
  }
  .whyThinkSabioVideo {
    width: 45%;
    position: relative;
    background: white;
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      //background: rgba(0, 0, 0, 0.1);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
    video {
      margin-top: 100px;
      object-fit: fill;
      height: 80%;
      width: 100%;
    }
    .playimage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70px;
      margin: auto;
    }
  }
}
.aboutThinksabio {
  // height: calc(100vh - 84px);
  background: #0E1118;
  padding: 70px 50px 50px 100px;
  height: 100vh;
  h2 {
    font-size: 48px;
    text-align: center;
    font-family: $bold;
    line-height: 65px;
    margin-bottom: 25px;
    color: #fff;
  }
  p {
    font-family: $regular;
      font-size: 22px;
      color: #fff;
      line-height: 28px;
      margin-bottom: 30px;
      text-align: center;
    span {
      color: #2EB558;
    }
  }
  .aboutThinksabioContent {
    //max-width: 1280px;
    max-width: 1000px;
    margin: auto;
    padding-top: 50px;
     h6 {
        font-size: 18px;
        line-height: 25px;
        font-family: $regular;
        color: #fff;
        margin-bottom: 65px;
        max-width: 262px;
     }
  }
  .aboutIconText {
    @include display-flex();
    margin-bottom: 15px;
    img {
      height: 80px;
      width: 80px;
    }
    p {
      font-size: 30px;
      font-family: $bold;
      line-height: 42px;
      color: #FFFFFF;
      @include align-self();
      padding-left: 15px;
      margin-bottom: 0;
    }
  }
}
.bestReturn {
  height: 110vh;
  .bestReturnContentText {
     @include display-flex();
     height: 85%;
  }
  .bestReturnTextimage {
     width: 55%;
     position: relative;
     padding: 100px 50px 100px 100px;
     @include align-self();
    //  &::before {
    //   content: '';
    //   position: absolute;
    //   height: 100%;
    //   background: rgba(0, 0, 0, 0.4);
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   width: 100%;
    // }
     img {
       margin-top: 50px;
        width: 80%;
        height: 70%;
        object-fit: fill;
     }
  }
  .returnTextimage {
    width: 55%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
  }
  .bestReturnTextText,.bestReturnTextValue {
    width: 45%;
    @include align-self();
    margin-top: 50px;
    padding: 50px 100px 50px 50px;
    h2 {
      font-size: 1.75rem;
      color: #161614;
      font-family: $bold;
    }
    h4 {
      font-size: 1.5rem;
      color: #161614;
      font-family: $bold;
    }
    p {
      font-family: $regular;
      font-size: 18px;
      color: #161614;
      line-height: 28px;
      margin-bottom: 5px;
      max-width: 500px;
    }
    button {
      color: #259745;
      height: 45px;
      width: 150px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #259745 !important;
      font-size: 20px;
      outline: 0;
      font-family: $regular;
      background: transparent;
    }
  }
  .bestReturnTextText {
    padding: 50px 50px 50px 10px;
  }
  .bestReturnTextValue {
     p {
       margin-bottom: 40px;
     }
  }
}
.community {
  height: 100vh;
  background: #0E1118;
}
.communityContent {
    @include display-flex();
    height: 100%;
}
.communityContentText {
    width: 45%;
    @include align-self();
    padding: 50px 50px 50px 100px;
    h2 {
      font-size: 48px;
      line-height: 64px;
      color: #FFFFFF;
      font-family: $bold;
    }
    p {
      font-family: $regular;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 28px;
      margin-bottom: 70px;
      max-width: 400px;
    }
    button {
      color: #259745;
      height: 45px;
      width: 150px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #259745 !important;
      font-size: 20px;
      outline: 0;
      font-family: $regular;
      background: transparent;
    }
  }
  .communityContentImage {
     width: 55%;
     position: relative;
     &::before {
      content: '';
      position: absolute;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
     img {
       height: 100vh;
       width: 100%;
       object-fit: cover;
     }
  }
.captain {
  height: 100vh;
}
.captainImage {
   height: calc(100vh - 230px);
   img {
     height: 100%;
     width: 100%;
   }
}
.captainContent,.valueContent {
  @include display-flex();
  height: 230px;
  background: #0E1118;
  padding: 40px 10% 50px;
  align-items: baseline;
  h2 {
    font-size: 48px;
    line-height: 45px;
    margin-bottom: 20px;
    color: #FFFFFF;
    font-family: $bold;
  }
  p {
    font-family: $regular;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 28px;
    max-width: 500px;
  }
  button {
    color: #259745;
    height: 45px;
    width: 150px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #259745 !important;
    font-size: 20px;
    outline: 0;
    font-family: $regular;
    background: transparent;
  }
}
.valueContent {
   background: $white;
   padding: 50px 10%;
   h2 {
    color: #0E1118;
   }
   p {
    color: #0E1118;
   }
}
.captainContentText {
  width: 350px;
  p {
    display: none;
    opacity: 0;
  }
}
.captainRight {
  width: calc(100% - 350px);
  padding-left: 7%;
}
.scrollLinks {
  position: fixed;
  top: 150px;
  left: 50px;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin-bottom: 20px;
    a {
      height: 40px;
      width: 2px;
      background: #fff;
      opacity: 0.6;

      display: block;
    }
  }
}
.investBox {
    background: #0E1118;
    height: 100vh;
    min-height: 550px;
    @include display-flex();
    padding: 50px 5%;
    position: relative;
}
.investBoxContent {
    @include align-self();
    text-align: center;
    margin: auto;
    img{
      width: 420px;
      margin: 0 0 20px 0;
    }
    h2 {
        font-size: 48px;
        line-height: 64px;
        color: #FFFFFF;
        font-family: $bold;
    }
    button {
      color: #259745;
      height: 45px;
      width: 150px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #259745 !important;
      font-size: 20px;
      outline: 0;
      font-family: $regular;
      background: transparent;
    }
}
.homeFooterBox {
   background: white;
  color: black;
   padding: 30px 5%;
   bottom: 0;
   right: 0;
   left: 0;
   //position: absolute;
}
.homeFooterContent {
  width:100%;
    margin: auto;
    @include display-flex();
    p {
      font-size: 16px;
      margin-bottom: 0;
      font-family: $regular;
      color: black;
      width: 150px;
     }
   ul {
       margin: 0;
       padding: 0;
       list-style: none;
       @include display-flex();
       width: calc(100% - 150px);
       justify-content: flex-end;
       li {
          float: left;
          padding-right: 2.5%;
       }

       a {
          color: black;
          font-family: $regular;
          font-size: 16px;
       }
   }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .headerlink {
    .menuLinkHome {
      li {
        padding-left: 20px;
      }
    }
  }
  .homeBanner {
    .homeBannerText {
       h2 {
        font-size: 50px;
        line-height: 55px;
       }
    }
  }

  .whyThinkSabioContent {
    @include display-flex();
    height: 100%;
    .whyThinkSabioText {
      padding: 50px;
      h2 {
        font-size: 40px;
        line-height: 54px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  .aboutThinksabio {
    padding: 50px;
    height: auto;
    h2 {
      font-size: 40px;
      line-height: 50px;
    }
    p {
        font-size: 18px;
        line-height: 25px;
    }
    .aboutThinksabioContent {
      padding-top: 50px;
       h6 {
          font-size: 18px;
          line-height: 23px;
       }
    }
    .aboutIconText {
      p {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  .bestReturn {
    .bestReturnTextText {
      padding: 50px;
      h2 {
        font-size: 40px;
        line-height: 50px;
      }
      p {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }
  .communityContentText {
    padding: 50px;
    h2 {
      font-size: 40px;
      line-height: 50px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .investBoxContent {
    h2 {
        font-size: 40px;
        line-height: 50px;
    }
  }

}
@media (max-width: 767px) {
  .homeHeader {
    width:100% !important;
    top: 0px;
    position: relative;
    margin: 0 0 5px 0;
    //background: #000;
  }
  .headerlink {
    width: calc(100% - 200px);
    text-align: right;
    .menuLinkHome {
      display: none;
      opacity: 0;
    }
  }
  .logoHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
      width:150px;
      height: 30px;
    }
  }
  .mobileMenuHome {
    display: block;
    width:100%;
    opacity: 1;
    color: #ed9b3d;
    font-size: 27px;
    padding-right: 5px;
    margin-bottom: 0;
  }
  .mobileMenuNavLink {
    // background: #010001 !important;
    li {
      padding: 15px !important;
      border-bottom: 1px solid white;
      &:last-child {
        border: none;
      }
      a {
        font-size: 18px;
        font-family: $bold;
        color: #fff;
        outline: 0;
      }
    }
  }
  .firstLine {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin: 150px 20px 120px;
  }
  .firstParagraph {
    width: 80%;
    text-align: left;
    font-size: 18px;
    margin: auto;
  }
  .bannerVideo {
    //height: 400px;
    padding-top: 20px;
    height: auto;
    min-height: 100%;
    video {
      object-fit: fill;
    }
    .playCircle {
      top: 50px;
      img {
        height: 60px;
      }
    }
  }
  .homeBanner {
      height: 350px;
    .homeBannerText {
       h2 {
        font-size: 30px;
        line-height: 40px;
       }
    }
  }
  .whyThinkSabio {
    height: 100%;
  }
  .whyThinkSabioContent {
    flex-direction: column-reverse;
    .whyThinkSabioText {
      width: 100%;
      padding: 40px 5%;
      text-align: center;
      h2 {
        font-size: 25px;
        line-height: 34px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .whyThinkSabioVideo {
      width: 100%;
      height: 300px;
    }
  }
  .aboutThinksabio {
    padding: 40px 5%;
    height: auto;
    h2 {
      font-size: 25px;
      line-height: 34px;
    }
    p {
        font-size: 18px;
        line-height: 25px;
    }
    .aboutThinksabioContent {
      padding-top: 10px;
       h6 {
          font-size: 18px;
          line-height: 23px;
          max-width: 100%;
          text-align: center;
          margin-bottom: 30px;
       }
    }
    .aboutIconText {
      justify-content: center;
      p {
        font-size: 25px;
        line-height: 36px;
      }
    }
  }
  .bestReturn {
    height: 100%;
    .bestReturnContentText {
      display: block;
    }
    .bestReturnTextimage {
      width: 100%;
      padding: 5%;
      img {
        height: 100%;
        width:100%;
      }
    }
    .returnTextimage {
        width: 100%;
      }
    .bestReturnTextText,.bestReturnTextValue {
      width: 100%;
      padding: 40px 5%;
      text-align: center;
      h2 {
        font-size: 25px;
        line-height: 35px;
      }
      p {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 40px;
      }
    }
  }
  .community {
    height: 100%;
  }
  .communityContent {
    flex-direction: column-reverse;
  }
  .communityContentText {
    width: 100%;
    padding: 40px 5%;
    text-align: center;
    h2 {
      font-size: 25px;
      line-height: 35px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .communityContentImage {
    width: 100%;
    img {
      height: 100%;
    }
  }
  .captain {
    height: 100%;
  }
  .captainImage {
     height: auto;
     img {
       height: 100%;
       width: 100%;
     }
  }
  .captainContent,.valueContent {
    display: block;
    height: 100%;
    padding: 40px 5% 50px;
    h2 {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  }
  .captainContentText {
    width: 100%;
    text-align: center;
    p {
      display: block;
      opacity: 1;
    }
  }
  .captainRight {
    width: 100%;
    padding-left: 0;
    p {
      display: none;
      opacity: 0;
    }
  }
  .investBox {
    height: 100%;
    min-height: 300px;
    padding: 40px 5%;
    position: relative;
  }
  .investBoxContent {
    img{
      width: 200px;
    }
    h2 {
        font-size: 25px;
        line-height: 35px;
    }
  }
  .scrollLinks {
    display: none;
    opacity: 0;
    position: static;
  }
  .homeFooterContent {
    flex-direction: column-reverse;
    display: block;
    ul {
        //display: inline-block;
        display: block;
        width: 100%;
        margin-top: 20px;
        li {
          //padding-right: 5%;
          //margin-bottom: 20px;
          display: inline-block;
          float: none;
          margin-right: 0;
          padding: 0 10px;
        }
        p {
            font-size: 13px;
        }
        a {
            font-size: 13px;
        }
    }
    p {
      font-size: 13px;
      width: 100%;
    }
  }
  .homeFooterBox {
    position: relative;
    padding: 30px 0;
  }
}
@media (max-width: 525px) {
    .captainImage {
       height: 260px;
    }
}
