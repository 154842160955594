// @import '../../node_modules/antd/dist/antd.less';
// @import '../../node_modules/ant-design-pro/dist/ant-design-pro.css';
@import './theme.scss';
@import './colors.module';
@import './base.module';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #f5f7ff99;
  background-repeat: repeat;
  background-size: auto;
  bottom: 0;
  background-color: $dark_primary !important;
  color: $white !important;
  overflow: overlay;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}

#membership-form,
#donation-form {
  .ant-select-selector {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    // color: #32325d;
    // background-color: white;
    border: 1px solid rgba(0, 0, 52, 0.201512);
    border-radius: 0px;
    // box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }
  .ant-select-selection-search {
    line-height: 38px;
  }
  .ant-select-selection-item {
    line-height: 18px;
  }
  .StripeElement {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 52, 0.201512);
    border-radius: 0;
  }
}
#contactUs-form {
  .ant-form-item {
    padding: 0 8px;
    input {
      height: 36px;
      font-family: $regular;
      background-color: var(--inputbgm) !important;
      color: var(--whitecolor) !important;
      border: 1px solid var(--grayborder) !important;
      box-shadow: var(--inputshadow) !important;
      &:hover {
        border: 1px solid #3cc967 !important;
      }
    }
    textarea {
      resize: none;
      font-family: $regular;
      background-color: var(--inputbgm) !important;
      color: var(--whitecolor) !important;
      border: 1px solid var(--grayborder) !important;
      box-shadow: var(--inputshadow) !important;
      &:hover {
        border: 1px solid #3cc967 !important;
      }
    }
  }
  .ant-form-item-label > label {
    font-family: $regular;
    font-size: 18px;
    // color: $black;
    color: var(--whitecolor) !important;
    margin-bottom: 10px;
  }
  .ant-form-text {
    font-family: $regular;
    font-size: 18px;
    color: $black;
    margin-bottom: 10px;
    padding: 0 8px;
  }
  .ant-btn-primary {
    width: 50%;
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
    box-shadow: none !important;
  }
  label {
    color: var(--whitecolor) !important;
  }
}
#refer-form {
  .ant-form-item {
    input {
      height: 36px;
      font-family: $regular;
      // border: 1px solid rgba(0, 0, 52, 0.201512);
      background-color: var(--inputbgm) !important;
      color: var(--whitecolor) !important;
      border: 1px solid var(--grayborder) !important;
      box-shadow: var(--inputshadow) !important;
      &:hover {
        border: 1px solid #3cc967 !important;
      }
    }
  }
}
#tools-form {
  .ant-form-item {
    input {
      font-family: $regular;
       //border: 1px solid rgba(0, 0, 52, 0.201512);
      background-color: var(--inputbgm) !important;
      //color: var(--whitecolor) !important;
    }
  }
}
//<---------------------------Pie chart starts---------------------------------->
// #portfolioSnapshot-sector {
//   .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend
//     .antd-pro-charts-pie-chart {
//     width: 150px;
//   }
// }
// #portfolioSnapshot-symbol {
//   .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend
//     .antd-pro-charts-pie-chart {
//     width: 150px;
//   }
// }
// .antd-pro-charts-pie-pie .antd-pro-charts-pie-legendTitle {
//   // color: $green_text-color !important;
//   color: var(--btncolor) !important;
//   // font-family: 'Circular-Loom';
//   // word-spacing: -4px;
// }
.ant-divider-vertical {
  border-left: 1px solid $common_border !important;
}
// .antd-pro-charts-pie-pie .antd-pro-charts-pie-percent {
//   // color: $common_text_color !important;
//   color: var(--breadcrumb) !important;
//   // font-family: 'Circular-Loom';
//   // word-spacing: -4px;
// }
// .antd-pro-charts-pie-pie .antd-pro-charts-pie-value {
//   // color: $common_text_color !important;
//   color: var(--breadcrumb) !important;
// }
// #portfolioSnapshot-sector .antd-pro-charts-pie-pie {
//   height: 180px !important;
// }
// #portfolioSnapshot-sector .antd-pro-charts-pie-legend:hover::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }
// #portfolioSnapshot-sector .antd-pro-charts-pie-legend li {
//   width: auto !important;
//   margin: 0 20px 2% 0 !important;
//   //flex-basis: 24% !important;
//   min-width: 4% !important;
//   flex-basis: 30% !important;
//   flex-grow: 0 !important;
//   height: auto !important;
//   display: flex !important;
//   align-items: baseline !important;
// }

// #portfolioSnapshot-sector .ant-divider-vertical {
//   display: none !important;
// }
// #portfolioSnapshot-symbol .ant-divider-vertical {
//   display: none !important;
// }

// #portfolioSnapshot-symbol .antd-pro-charts-pie-pie {
//   height: 180px !important;
// }
// #portfolioSnapshot-symbol .antd-pro-charts-pie-legend:hover::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }
// #portfolioSnapshot-symbol .antd-pro-charts-pie-legend li {
//   width: auto !important;
//   margin: 0 0 2% 0 !important;
//   //flex-basis: 24% !important;
//   min-width: 4% !important;
//   flex-basis: 25% !important;
//   flex-grow: 0 !important;
//   height: auto !important;
//   display: flex !important;
//   align-items: baseline !important;
// }
// #portfolioSnapshot-symbol .antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
//   top: 35% !important;
//   padding: 1% 0 0 0 !important;
// }
// #portfolioSnapshot-symbol .antd-pro-charts-pie-legend {
//   align-items: baseline !important;
// }
// #portfolioSnapshot-symbol
//   .antd-pro-charts-pie-pie
//   .antd-pro-charts-pie-legendTitle {
//   word-break: break-word !important;
//   display: inline-block !important;
//   //width: 40% !important;
//   margin: 0 10px 0 0 !important;
//   font-size: 12px !important;
// }
// .antd-pro-charts-pie-pie .antd-pro-charts-pie-percent {
//   font-size: 12px !important;
// }
//<----------------------------Pie chart ends------------------------------------>

#isr {
  .ant-collapse {
    background: var(--drawer) !important;
    border: 1px solid var(--newborder) !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: var(--background) !important;
    color: var(--newcolor) !important;
  }
  .ant-table {
    // background-color: $table_bg_color !important;
    background: var(--background) !important;
    border-bottom: 0px !important;
  }
  .ant-table-thead > tr > th {
    background: var(--greenthead) !important;
  }
}

// <-------------Table Style start -------------------->
// #isr .ant-table {
//   //background-color: $dark_primary !important;
//   background-color: $table_bg_color !important;
//   border-bottom: 0px !important;
// }
#isr .ant-table-body {
  overflow-y: auto !important;
}
.ant-table {
  // background-color: $dark_secondary !important;
  // color: $white !important;
  background-color: var(--background-color) !important;
  color: var(--graybody) !important;
}
.ant-empty-normal {
  max-width: 100vw;
}
#isr .ant-table table {
  // border-bottom: 0.5px solid #495057 !important;
  // border-bottom: 0.5px solid var(--borderright) !important;
  border: 1px solid var(--newborder) !important;
}

#isr {
  #forecastingTable {
    // tr > th {
    //   background: $dark_secondary !important;
    // }
    th {
      padding: 5px;
      text-align: center;
      border-bottom: 1px solid #46515c !important;
      border-right: 1px solid #46515c !important;
      border-top: 1px solid #46515c !important;
      font-size: 14px !important;
    }

    td {
      // background: var(----newTbodyColor) !important;
      padding: 5px !important;
      text-align: center;
      border-right: 1px solid #46515c !important;
      border-bottom: 1px solid #46515c !important;
      font-size: 12px !important;
      svg {
        color: $green;
      }
    }
  }
}

//.ant-table-wrappefr{
// display: flex;
// justify-content: center;
// }
.ant-table table {
  min-width: auto !important;
  // border-top: 1px solid $black1 !important;
  border-top: 1px solid var(--borderright) !important;
  // border-bottom: 1px solid $black1 !important;
  border-bottom: 1px solid var(--borderright) !important;
  // border-left: 1px solid $black1 !important;
  // border-left: 1px solid var(--borderright) !important;
  margin: auto !important;
  // width: auto !important;
}
// #smallTable .ant-table table {
//  width: auto !important;
//  border: 1px solid red !important;
// }
// .ant-table-container > .ant-table-header > table > thead > tr > th {
//   //background-color: $grey2 !important;
// }
.ant-table-small .ant-table-thead > tr > th {
  // background-color: $grey2 !important;
  background-color: var(--greenthead) !important;
}
.ant-table-small .ant-table-thead > tr > th div {
  color: var(--blackth) !important;
  font-weight: var(--fontweight);
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid var(--lightborder) !important;
}
.ant-table-thead > tr > th {
  //background: $dark_secondary !important;
  //color: $white !important;
  // background: $table_header_bg_color !important;
  background: var(--greenthead) !important;
  color: $table_header_text_color !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  // background: $lightGrey !important;
  background: $dark_secondary !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid $black1 !important;
  border-bottom: 1px solid $black1 !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid $black1 !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid $black1 !important;
  border-bottom: 1px solid $black1 !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid $black1 !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid $black1 !important;
  border-top: 1px solid $black1 !important;
}
// .ant-table-cell-fix-left, .ant-table-cell-fix-right {
//   // background-color: $dark_primary !important;
// }
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  background-color: var(--tfootercolor) !important;
  // border-top:  1px solid var(--tdborder) !important;
  border-top: none !important;
}
// .ant-table.ant-table-small .ant-table-tbody > tr > td{
// padding: 5px 5px !important;
// }

// .ant-table.ant-table-bordered > .ant-table-container {
//   // border: 1px solid $black1 !important;
//   // border: 0px !important;
// }
.ant-table-column-sorter-inner {
  color: var(--careticoncolor);
  // span {
  //   color: var(--whitecolor) !important;
  // }
}
.ant-table-summary {
  background: $dark_secondary !important;
}
.ant-table-summary > tr > td {
  border-right: 1px solid var(--rightthborder) !important;
}
.ant-table tfoot > tr > td {
  border-bottom: 0px solid $black1 !important;
}
.ant-table-tbody > tr > td {
  // background: $table_body_bg_color !important;
  background: var(--newtbody) !important;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: none !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  // border-right: 1px solid $table_header_border_color !important;
  border-right: 1px solid var(--rightthborder) !important;
  //border-bottom: 1px solid $table_header_border_color !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid var(--tdborder) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th:last-child {
  border-right: none !important;
}
.ant-table-tbody > tr > td {
  // border-bottom: 1px solid $black1 !important;
  border-bottom: 1px solid var(--tdborder) !important;
  border-right: 1px solid var(--tdborder) !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid var(--rightthborder) !important;
  // border-bottom: 1px solid $table_header_border_color !important;
  border-bottom: 1px solid var(--tdborder) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-bottom: 1px solid var(--tdborder) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid var(--tdborder) !important;
  border-bottom: 1px solid var(--tdborder) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr.ant-table-measure-row
  > td {
  border-bottom: 1px solid var(--tdborder) !important;
  border-right: 1px solid var(--tdborder) !important;
}
.ant-table-tbody > tr.ant-table-measure-row > td {
  border-bottom: 1px solid var(--tdborder) !important;
  border-right: 1px solid var(--tdborder) !important;
}
.ant-table-cell table {
  // background-color: red !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
.ant-table-cell table th {
  // background: var(--newTheadColor) !important;
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid var(--grayborder) !important;
  border-right: 1px solid var(--grayborder) !important;
}
.ant-table-cell table th:last-child {
  border-right: 0 !important;
}
.ant-table-cell table td {
  // background: var(----newTbodyColor) !important;
  padding: 5px;
  text-align: center;
  border-right: 1px solid var(--grayborder) !important;
  border-bottom: 1px solid var(--grayborder) !important;
}
.ant-table-cell table td:last-child {
  border-right: 0 !important;
}
.ant-table-cell table tr:last-child td {
  border-bottom: 0 !important;
}
// <-------------Table Style End -------------------->

// <------------label style start & Form ------------------->

.ant-form-horizontal .ant-form-item-label {
  display: block !important;
  text-align: left !important;
}
.ant-form-item-label > label {
  color: var(--whitecolor) !important;
}
.ant-form-horizontal .ant-form-item {
  display: block !important;
}
.ant-form-item-label {
  padding: 0 !important;
}
.ant-form-item {
  margin-bottom: 18px !important;
}
.ant-form-item-control-input-content .ant-input-affix-wrapper {
  padding: 0px 11px 0px 0 !important;
  // border: 1px solid #eaeaea !important;
  // background: transparent !important;
}
.ant-form-item-control-input-content
  .ant-input-affix-wrapper
  > input.ant-input {
  border: none !important;
  padding: 8px !important;
  background: transparent !important;
}

.ant-form-item-control-input-content
  .ant-input-affix-wrapper
  > input.ant-input {
  border: none !important;
  padding: 7.5px !important;
  background: transparent !important;
}
#normal_login {
  .ant-form-item {
    margin-bottom: 24px !important;
  }
  .ant-input-affix-wrapper {
    padding: 0px 0px 0px 11px !important;
    color: #fff !important;
    border: 1px solid #eaeaea !important;
    opacity: 0.8 !important;
    background: transparent !important;
    border-radius: 6px;
    input {
      padding: 10px !important;
      color: #fff !important;
    }
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: -27px;
    font-size: 12px;
  }
  .ant-form-item-with-help {
    position: relative;
  }
}

// <-------------Input Style start -------------------->

.ant-input {
  // color: $white !important;
  color: var(--whitecolor) !important;
  background-color: var(--inputbgmnum) !important;
}

.ant-input-number-input,
.ant-input {
  //background-color: $dark_primary !important;
  // background-color: $input_bg !important;
  // color: $input_text_color !important;
  // background: var(--background-color) !important;
  // color: var(--color) !important;
  border: 1px solid var(--grayborder) !important;
  background: var(--inputbgmnum) !important;
  box-shadow: var(--inputshadow) !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // background-color: $dark_primary !important;
  background: var(--inputbgm) !important;
}

.ant-input[disabled] {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-input-affix-wrapper {
  // background-color: $dark_primary !important;
  // border: 1px solid $input_bg !important;
  // background: $input_bg !important;
  background-color: var(--inputbgm) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: var(--inputshadow) !important;
  .ant-input {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }
}
.ant-input-suffix {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  // color: $white !important;
  //  color: $input_text_color !important;
  color: var(--whitecolor) !important;
}
.ant-input-number {
  // border: 1px solid $input_bg !important;
  // background-color: $input_bg !important;
  background: var(--whiteborder) !important;
  border: 1px solid var(--grayborder) !important;
}

.ant-input-password-icon {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-cascader-picker {
  // background-color: $input_bg !important;
  background-color: var(--datepick) !important;
  color: $input_text_color !important;
  // border: 1px solid var(--border) !important;
  border: 1px solid var(--grayborder) !important;
}
.ant-cascader-picker-arrow {
  color: $input_text_color !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  // background-color: $input_bg !important;
  background-color: var(--inputbgm) !important;
  // color: $input_text_color !important;
  color: var(--whitecolor) !important;
  // border: 1px solid $input_bg !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: var(--inputshadow) !important;
}
// .ant-select-multiple .ant-select-selector:hover {
//   border: 1px solid #3cc967 !important;
// }
// .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
//   border: 1px solid #3cc967 !important;
// }
.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector:not(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  box-shadow: var(--inputshadow) !important;
  // border: 1px solid #1ABD50 !important;
}
.ant-select-focused.ant-select-multiple
  .ant-select-selector:not(.ant-select-disabled.ant-select-multiple
    .ant-select-selector) {
  box-shadow: var(--inputshadow) !important;
  // border: 1px solid #1ABD50 !important;
}
.ant-input {
  border: 1px solid $input_bg !important;
}
.ant-select-multiple .ant-select-selector {
  // background-color: $input_bg !important;
  // color: $input_text_color !important;
  // border: 1px solid $input_bg !important;
  background-color: var(--inputbgm) !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: var(--inputshadow) !important;
}
#reportSearchInputField .ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
}
#filter-form .ant-select-multiple .ant-select-selector {
  background-color: var(--whiteborder) !important;
  color: var(--whitecolor) !important;
  // color: $white !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: none !important;
  &:hover {
    border: 1px solid #3cc967 !important;
  }
}
#filter-form .ant-input-number-input,
#filter-form .ant-input {
  background-color: var(--whiteborder) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: none !important;
}
#filter-form {
  .ant-input-number {
    input {
      border: none !important;
      background: none !important;
    }
  }
  button {
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
    // color: var(--buttonColor) !important;
    &:hover {
      // color: $green !important;
      color: var(--greencolor) !important;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
}
#sort-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--whiteborder) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: none !important;
  &:hover {
    border: 1px solid #3cc967 !important;
  }
}
#sort-form {
  button {
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
    &:hover {
      // color: $green !important;
      color: var(--greencolor) !important;
    }
  }
}
#save-form {
  background-color: var(--drawer) !important;
  label {
    color: var(--whitecolor) !important;
  }
  input {
    background-color: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
  button {
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
    &:hover {
      // color: $green !important;
      color: var(--greencolor) !important;
    }
  }
}
#Create-Alert,
#Update-Alert {
  label {
    color: var(--whitecolor) !important;
  }
  input {
    background-color: var(--whiteborder) !important;
    // border: 1px solid var(--border) !important;
    // border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
    color: var(--whitecolor) !important;
  }
  #Create-Alert_name {
    border: 1px solid var(--grayborder) !important;
  }
  #Create-Alert_sharesActivity {
    border: none !important;
  }
  #Update-Alert_name {
    padding: 10.5px 12px !important;
    font-size: 16px !important;
    border: 1px solid var(--grayborder) !important;
  }
  button {
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
  }
  .ant-select-multiple .ant-select-selector {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
}
.vPortStockSearchBar {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--thead) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--thead) !important;
  }
}
.ant-breadcrumb {
  // color: $breadcrum_text !important;
  color: var(--breadcrumb) !important;
}
.ant-breadcrumb > span:last-child {
  // color: $breadcrum_text !important;
  color: var(--breadcrumb) !important;
}
.ant-breadcrumb-separator {
  // color: $breadcrum_text !important;
  color: var(--breadcrumb) !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: $dark_primary !important;
  font-size: 14px !important;
  color: $white !important;
}
// <-------------Input Style ENd -------------------->

//<!------------Collapse Styles Start---------------->
.ant-collapse {
  background: $dark_primary !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: $green !important;
  background: $table_bg_color !important;
}
.ant-collapse-content {
  background: $collapse_box_bg_color !important;
}
.ant-collapse {
  border: 1px solid $collapse_border !important;
}
.ant-collapse > .ant-collapse-item {
  // border-bottom: 1px solid $collapse_border !important;
  border-bottom: 1px solid var(--grayborder) !important;
}
.ant-collapse-content {
  // border-top: 1px solid $collapse_border !important;
  border-top: 1px solid var(--grayborder) !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: relative !important;
  margin-right: 10px;
  margin-top: 10px;
  padding-right: 10px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
//<------------Collapse Styles End ------------------------>

//<----------------------List Styles Starts --------------->
.ant-list-item-meta-title {
  color: #d8d8d8 !important;
}

//<!--------------------List Styles Starts------------------>

//<!-------------Date Picker ------------------------------->
.ant-picker {
  // background: $date_input_picker_bg !important;
  // border: 1px solid $date_input_picker_bg !important;
  background-color: var(--whiteborder) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
}
.ant-picker-suffix {
  // color: $white !important
  color: var(--whitecolor) !important;
}
.ant-picker-panel-container .ant-picker-panel {
  // background: $date_input_picker_bg !important;
  background: var(--datepick) !important;
}
.ant-picker-header-view button {
  color: $date_picker_btn !important;
}
.ant-picker-cell-disabled::before {
  // background: $date_input_picker_bg !important;
  background: var(--datepick) !important;
}
// .ant-picker-cell-disabled .ant-picker-cell-inner{
//   color: $date_picker_disabled_color !important;
// }
.ant-picker-cell-in-view {
  color: $green !important;
}
// .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
//   // background: $dark_primary !important;
// }
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: $green !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
  background: $green !important;
}
.ant-picker-range-separator {
  .anticon {
    color: $white !important;
  }
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  // background-color: $dark_secondary !important;
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-picker {
  // background-color: $dark_primary !important;
  background-color: var(--whiteborder) !important;
  color: var(--whitecolor) !important;
  border: 1px solid var(--grayborder) !important;
}
.ant-picker-input > input {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-picker-content th {
  color: $white !important;
}
// .ant-picker-cell-in-view {
//   color: $white !important;
// }
.ant-picker-header {
  color: $white !important;
}
.ant-picker-header button {
  // color: $white !important;
  color: var(--whitecolor) !important;
  border: none !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: none !important;
  border: 1px dotted $green !important;
}
.ant-tabs-tabpane {
  .ant-row-space-between {
    .ant-picker {
      background-color: var(--inputbgm) !important;
      color: var(--whitecolor) !important;
      border: 1px solid var(--grayborder) !important;
      box-shadow: var(--inputshadow) !important;
    }
  }
}
.ant-picker-range-separator .anticon {
  color: var(--whitecolor) !important;
}
// <-------------Pagination Style Start -------------------->

.ant-pagination-item {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  // box-shadow: var(--newboxshadow);
  box-shadow: var(--inputshadow);
  border: 1px solid var(--grayborder) !important;
}
.ant-pagination-item-active {
  // background-color: $dark_primary !important;
  background-color: var(--newpagination) !important;
  border: 1px solid $green !important;
}
.ant-pagination-item a {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-pagination-item-active a {
  color: $white !important;
  &:hover {
    color: $white !important;
  }
}
.ant-pagination-item-link {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  // color:$white !important;
  color: var(--whitecolor) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  div {
    color: $green !important;
  }
}
.ant-picker-panel-container {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
}
.ant-pagination-options
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--headerbgm) !important;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: var(--whitecolor) !important;
}

// <-------------Pagination Style End -------------------->

.antd-pro-charts-pie-legend {
  width: calc(100% - 200px);
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  overflow: overlay;
  align-items: center;
  li {
    width: 215px;
    margin: 0px;
  }
}
.antd-pro-charts-pie-pie .antd-pro-charts-pie-value {
  position: relative;
  padding-left: 10px;
}
.antd-pro-charts-pie-pie .antd-pro-charts-pie-dot {
  border-radius: 2px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px 8px 0 !important;
  width: 150px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
}
.ant-tabs-top > .ant-tabs-nav::before {
  // border-bottom: 1px solid $collapse_border !important;
  border-bottom: 1px solid var(--tabborder) !important;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  border-left: 2px solid var(--grayborder) !important;
  margin-left: -2px !important;
}

// .ant-form-item-label > label {
//   color: $white !important;
// }

.ant-select-dropdown {
  // background-color: $dark_primary !important;
  background-color: var(--drawer) !important;
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-select-item-option-content {
  color: var(--whitecolor) !important;
}
.ant-card {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  border: 1px solid var(--grayborder) !important;
  box-shadow: var(--newboxshadow);
}
// #vPortSide {
//   // border: 1px solid var(--border) !important;
// }

// <-------------Pie Chart  start -------------------->
// #dashboard-snapshot {
//   .antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
//     position: relative !important;
//     transform: none !important;
//   }
//   .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend
//     .antd-pro-charts-pie-chart {
//     width: 100%;
//   }
//   // .antd-pro-charts-pie-pie .antd-pro-charts-pie-dot{
//   //   border-radius: 0px;
//   // }
// }

.ant-select-item {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  // background-color: $dark_secondary !important;
  background-color: var(--background-color) !important;
}
.ant-select {
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}
.ant-select-arrow {
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}

// .ant-card-bordered{
//   height: 100%;
// }
// <-------------Pie Chart  End -------------------->

.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend li {
  pointer-events: none !important;
}
.antd-pro-charts-pie-pie .antd-pro-charts-pie-legend li:last-child {
  margin-bottom: 16px !important;
  margin-left: 5px !important;
}

.ant-menu-horizontal > .ant-menu-item a {
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  // color: $green !important;
  color: var(--whitecolor) !important;
}
.ant-btn {
  // color: $green !important;
  color: var(--btncolor) !important;
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  background: var(--headerbgm) !important;
  border: 1px solid var(--btncolor) !important;
  // color: var(--buttonColor) !important;
  &:hover {
    color: $green !important;
  }
}
.ant-checkbox-disabled + span {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-radio-wrapper {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
// <-------------Drawer  Start -------------------->
.ant-drawer-content {
  // background-color: $dark_primary !important;
  background-color: var(--drawer) !important;
}
.ant-drawer-header {
  // background-color: $dark_primary !important;
  background-color: var(--drawer) !important;
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}
.ant-drawer-close {
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}
.ant-drawer-title {
  // color: $white !important;
  // color: var(--color) !important;
  color: var(--whitecolor) !important;
}

// <-------------Drawer  End -------------------->
.ant-checkbox-wrapper {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
// <-------------Cascader  Start -------------------->

.ant-cascader-menus {
  // background-color: $dark_primary !important;
  background-color: var(--drawer) !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  // background-color: $dark_secondary !important;
  background: var(--backgroundcolor) !important;
  color: $green !important;
}
.ant-cascader-menu-item:hover {
  // background-color: $dark_secondary !important;
  background: var(--backgroundcolor) !important;
  color: $green !important;
}
// .ant-cascader-menus li {
//   //color: $green !important
// }
.ant-cascader-menu-item {
  color: var(--whitecolor) !important;
}
.ant-cascader-menu-item-expand {
  color: var(--whitecolor) !important;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
// .ant-cascader-menu{
//   // border-color:  $green !important;
// }
.ant-cascader-picker-label {
  // color: $green !important
  color: var(--newcolor) !important;
}
.ant-cascader-picker-arrow {
  // color: $green !important
  color: var(--newcolor) !important;
}

// <-------------Cascader  End -------------------->

// <-------------Modal  Start -------------------->

.ant-modal-content,
.ant-modal-header {
  // background-color: $dark_primary !important;
  background-color: var(--drawer) !important;
}
.ant-modal-header {
  // border-bottom: 1px solid $common_border !important;
  border-bottom: 1px solid var(--grayborder) !important;
}
.ant-modal-title {
  color: $green_text-color !important;
}
.ant-modal-close {
  border: 0 !important;
  // color: $common_text_color !important;
  color: var(--breadcrumb) !important;
}
// <-------------Modal  End -------------------->
.ant-tabs-tab {
  // color: $white !important;
  color: var(--whitecolor) !important;
  // font-family: 'Circular-Loom';
  // word-spacing: -4px;
}
// .ant-tabs-tab-btn {
//   // font-family: 'Circular-Loom';
//   // word-spacing: -4px;
// }
.ant-tabs-tab + .ant-tabs-tab {
  // margin: 0px !important; // For My   recent and saved searched
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  // font-weight: var(--fontweight) !important;
  color: $green !important;
  // font-family: 'Circular-Loom';
}
.ant-menu-horizontal > .ant-menu-item-selected:after,
.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom: 0px !important;
}
.ant-tabs-ink-bar {
  color: $green !important;
}
.ant-layout-footer {
  background-color: $dark_primary !important;
  color: $white !important;
}

.ant-layout-footer {
  background-color: $dark_secondary !important;
}
.ant-menu {
  background-color: $dark_primary !important;
  color: $white !important;
}
.ant-empty-normal {
  color: $white !important;
}

// .anticon{
//   color: $icon_dropdown_color !important;
// }
.ant-empty-description {
  // color: $common_text_color !important;
  color: var(--nodata) !important;
}
.ant-dropdown-menu-submenu-arrow span {
  color: $white !important;
}

// <-------------Dropdown  Start -------------------->

.ant-dropdown-menu {
  // background-color: $dark_primary !important;
  background-color: var(--headerbgm) !important;
  .ant-switch {
    margin-left: 20px;
  }
  .ant-switch-handle {
    top: 1px;
  }
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  // background-color: $dark_secondary !important;
  background-color: var(--background-color) !important;
}

.ant-dropdown-menu-item-group-title {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  // color: $white !important;
  color: var(--whitecolor) !important;
}
.ant-dropdown-menu-submenu-title {
  padding: 0px !important;
}
// <-------------Dropdown  End -------------------->

// <--------------- Menu Start -------------------->
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  // color: $common_text_color !important;
  color: var(--graycolor1) !important;
}
// <--------------- Menu End ---------------------->
.ant-typography {
  // color: $common_text_color !important;
  color: var(--whitecolor) !important;
}
.ant-card-bordered {
  // border: 1px solid $common_border !important;
  border: 1px solid var(--grayborder) !important;
}
.ant-card-hoverable {
  transition: 0.4s !important;
}
.ant-card-hoverable:hover {
  transform: translate(0, -3px) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td {
  // background-color: $input_bg !important;
  // border-right: 1px solid #8c86865e !important;
  background-color: var(--grayborder) !important;
  border-right: 1px solid var(--rightthborder) !important;
  border-top: 1px solid var(--grayborder) !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after {
  border: none !important;
}
.ant-drawer-header {
  // border-bottom: 1px solid $common_border !important;
  border-bottom: 1px solid var(--grayborder) !important;
}
button {
  // border: 1px solid $common_border !important;
  border: 1px solid var(--grayborder) !important;
}
.ant-drawer-close {
  border: 0 !important;
}
._hj-3J5sV__MinimizedWidgetBottom__open._hj-1JS2b__MinimizedWidgetBottom__right {
  border: none !important;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  color: $common_text_color !important;
}
.ant-card-head {
  // border-bottom: 1px solid $common_border !important;
  border-bottom: 1px solid var(--newborder) !important;
  color: $green_text-color !important;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--sorterColor) !important;
}
.ant-modal-confirm-title {
  color: $green_text-color !important;
}
.ant-modal-confirm-content {
  // color: $common_text_color !important;
  color: var(--breadcrumb) !important;
}
.ant-layout-header {
  height: 84px !important;
  background-color: $dark_primary !important;
  z-index: 5 !important;
}
#column-form {
  label {
    color: var(--whitecolor) !important;
  }
  button {
    border: 1px solid var(--newgray) !important;
    color: var(--greencolor) !important;
    background-color: var(--transparent) !important;
    font-weight: var(--fontweight);
  }
}
#portfolioType {
  .ant-switch {
    background-color: $green !important;
  }
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
  .ant-select-selection-search-input {
  text-transform: uppercase;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector input {
  text-transform: uppercase;
}

// .ant-select-multiple .ant-select-selection-search {
//   margin-left: 0px !important;
// }
// .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input{
//   margin-left: 0px !important;
// }
#Reports {
  #SSL,
  #DISS {
    max-width: 720px;
    margin: auto;
  }
  #SSLC {
    max-width: 700px;
    margin: auto;
  }
  #ADUD,
  #SR {
    max-width: 1440px;
    margin: auto;
  }

  #TS {
    max-width: 2000px;
    margin: auto;
  }

  #AINB {
    max-width: 1700px;
    margin: auto;
  }
  #AID,
  #ADMA,
  #DTU,
  #DTD,
  #DDTU,
  #DDTD,
  #DDTDV,
  #DDTUV,
  #AIO,
  #IS,
  #DR {
    max-width: 2800px;
    margin: auto;
  }
  #DI {
    max-width: 1550px;
    margin: auto;
  }
}
#SEARCH {
  #TRENDING_STOCK {
    max-width: 2000px;
    margin: auto;
  }
  #TOP_GAINERS {
    max-width: 1000px;
    margin: auto;
  }
  // #ALLOCATIONS {
  //   max-width: 720px;
  //   margin: auto;
  // }
  #SYMBOL_SUMMARY,
  #INSIDER_SYMBOL_SUMMARY {
    max-width: 800px;
    margin: auto;
  }
  #OPTION_ALL_INSTITUTION {
    max-width: 900px;
    margin: auto;
  }
  .ant-tag-success {
    .ant-tag-close-icon {
      color: #1abd50 !important;
    }
  }
}
#allPicksId {
  // border-bottom: 0.5px solid #495057 !important;
  border-bottom: 0.5px solid var(--borderright) !important;
  box-shadow: var(--inputshadow);
}
// #allPicksId .ant-table-content{
//   // overflow: unset !important;
// }
#allPicksId table {
  border-top: 0 !important;
  border-bottom: 0 !important;
}
#allPicksId table > thead > tr > th,
#allPicksDetailsId table > thead > tr > th {
  // border-right: 1px solid #8c86865e !important;
  border-right: 1px solid var(--rightthborder) !important;
  // border-left: 1px solid #8c86865e !important;
}
#allPicksId .ant-table-tbody {
  min-height: 200px;
  background: #21272c !important;
}
#allPicksId .ant-table-tbody > tr > td,
#allPicksDetailsId .ant-table-tbody > tr > td {
  // background: #21272c !important;
  background: var(--tbodytd) !important;
  padding: 8px 5px;
  // &:last-child {
  //   // p {
  //   //   // color: var(--graycolor1) !important;
  //   // }
  // }
}
// #allPicksId .ant-table-tbody > tr:last-child td,
// #allPicksDetailsId .ant-table-tbody > tr:last-child td {
//   // border-bottom: 0 !important;
// }
#allPicksId .ant-table-tbody > tr.ant-table-row:hover > td,
#allPicksDetailsId .ant-table-tbody > tr.ant-table-row:hover > td {
  // background: #363E46 !important;
  background: var(--tdhover) !important;
  color: var(--whitecolor);
}
#allPicksId .ant-table-cell-scrollbar,
#allPicksDetailsId .ant-table-cell-scrollbar {
  box-shadow: none !important;
}
#allPicksId .ant-table-body {
  overflow: overlay !important;
}
#stockTable #allPicksId .ant-table-body {
  overflow: unset !important;
}
#healthCheck #allPicksId .ant-table-body {
  overflow: unset !important;
}
#healthCheck {
  .ant-table table {
    width: 100% !important;
  }
}
#allPicksId .ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#allPicksId .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ffffff7a;
  // margin: 0 5px 5px 0;
}
#allPicksId .ant-table-body::-webkit-scrollbar-track {
  background-color: var(--scroll2);
  border-radius: 10px;
  // margin: 0 5px 5px 0;
}
.ant-table-cell-scrollbar {
  box-shadow: none !important;
}
#allPicksId .ant-table-body:hover::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
  right: -10px;
}
#allPicksId .ant-table-body:hover::-webkit-scrollbar-corner {
  width: 10px;
  /*background-color: red !important;*/
}
#allPicksId .ant-table-tbody > tr:last-child td,
#allPicksDetailsId .ant-table-tbody > tr:last-child td {
  padding-bottom: 15px !important;
  //background: #F00 !important;
}
#allPicksId .ant-table-summary:hover tr td {
  padding-bottom: 13px !important;
}
#isr {
  .ant-table-tbody > tr > td {
    background: var(--tbodytd) !important;
    border-right: 1px solid var(--grayborder) !important;
    border-bottom: 1px solid var(--grayborder) !important;
    &:last-child {
      border-right: none !important;
    }
  }
  // .ant-table-tbody > tr:first-child td {
  //   display: none; // pick detail issue
  // }
  .ant-table-tbody > tr:last-child td {
    border-bottom: none !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border: none !important;
  }
}
#add-form {
  label {
    color: var(--whitecolor) !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
  }
  .ant-input-number-input,
  .ant-input,
  .ant-input-number {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
  }
  .ant-input-number {
    input {
      border: none !important;
      background: none !important;
    }
  }
  button {
    border: 1px solid var(--newgray) !important;
    background-color: var(--transparent) !important;
    color: var(--greencolor) !important;
    font-weight: var(--fontweight);
  }
}
#stock-form {
  .ant-input-number {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
    input {
      border: none !important;
      background: none !important;
    }
  }
}
#mySavedSearches {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px !important; // For My   recent and saved searched
  }
}
#trendingStockTab {
  #trendingStockTab-tab-GAINERS {
    color: $green !important;
  }
  #trendingStockTab-tab-PENNY_GAINERS {
    color: $green !important;
  }
  #trendingStockTab-tab-DECLINERS {
    color: $red !important;
  }
  #trendingStockTab-tab-PENNY_DECLINERS {
    color: $red !important;
  }
  #trendingStockTab-tab-ACTIVES {
    color: var(--whitecolor) !important;
  }
  .ant-tabs-tab {
    padding-top: 0px !important;
  }
}
#settingTab {
  .ant-tabs-nav {
    min-width: 165px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 2.5px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $green !important;
  }
}
// .ant-dropdown-menu-submenu-popup {
//   margin: -12px 0 0 10px !important;
//   box-shadow: none !important;
//   left: 865px !important;
// }
// .ant-dropdown-menu-submenu-popup ul {
//   margin: -12px 0 0 10px !important;
//   box-shadow: none !important;
//  }
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
  line-height: 50px !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 49px !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 49px !important;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 40px !important;
}
.ant-input-number-lg input {
  line-height: 47px !important;
  height: 47px !important;
}

.ant-cascader-input.ant-input {
  height: 100%;
  background: var(--whiteborder) !important;
}
.ant-card .ant-cascader-picker {
  background: var(--whiteborder) !important;
  border: 1px solid var(--grayborder) !important;
  input {
    background: none !important;
    border: none !important;
  }
}
#student-form {
  .ant-progress-steps-item {
    width: 45px !important;
  }
  .ant-progress-text {
    color: $green !important;
  }
}

// #faqsTabsBox .ant-tabs-nav {

// }
// #faqsTabsBox .ant-tabs-content-holder {
//    border-left: 0 !important;
// }
// #faqsTabsBox .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
//   display: none !important;
// }
// #faqsTabsBox .ant-tabs-tab {
//   text-align: center;
//   padding: 10px 15px !important;
// }
// #faqsTabsBox .ant-tabs-tab div {
//   width: 100%;
// }
// #faqsTabsBox .ant-tabs-tab-active {
//   background: #7d7d7d69 !important;
//   border-radius: 5px;
// }
#vDeleteAll .ant-card-actions {
  background: transparent !important;
  border-top: 0 !important;
}
#vDeleteAll .ant-card-actions > li {
  margin: 0 0 !important;
}
#vFormId .ant-form-item-label > label {
  font-size: 16px !important;
  padding-bottom: 7px;
  // color: var(--color) !important;
}
#vFormId {
  .ant-input[disabled],
  .ant-input {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
    input {
      border: none !important;
      background: none !important;
    }
    &:hover {
      border: 1px solid #3cc967 !important;
    }
  }
  .ant-input-number-input {
    &:hover {
      border: 1px solid #3cc967 !important;
    }
  }
  .ant-input {
    // box-shadow: none !important;
    &:hover {
      border: 1px solid #3cc967 !important;
    }
  }
  #change-password_oldPassword,
  #change-password_newPassword,
  #change-password_confirm {
    border: none !important;
  }
  .ant-input-number {
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
    .ant-input,
    .ant-input-number-input {
      border: none !important;
      background: none !important;
    }
    &:hover {
      border: 1px solid #3cc967 !important;
    }
  }
  // .ant-input-number-input-wrap input {
  //   // background-color: var(--inputbgm) !important;
  //   // color: var(--whitecolor) !important;
  //   // border: 1px solid var(--grayborder) !important;
  //   // box-shadow: var(--inputshadow) !important
  // }
  .ant-input-affix-wrapper {
    // background-color: var(--thead) !important;
    // border: 1px solid var(--border) !important;
    background-color: var(--inputbgm) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: var(--inputshadow) !important;
  }
  .ant-input-suffix,
  .ant-input-password-icon {
    color: var(--whitecolor) !important;
  }
  #financial-info-form_investmentRange {
    border: none !important;
    background: none !important;
  }
}
.ant-tag-success {
  background: transparent !important;
  border-color: $green_text-color !important;
  color: $green_text-color !important;
}
// #vSearch .ant-space-align-center {
//   align-items: baseline !important;
//   gap: 60px !important;
// }

// #vTabs.ant-tabs-top > .ant-tabs-nav {
//   max-width: 1400px !important;
//   margin: 0 auto !important;
//   width: 93% !important;
//   padding-top: 80px;
// }
#vTabsTrending.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0 0 !important;
}
// #vTabs-panel-REAL .ant-tabs-top > .ant-tabs-nav {
//   margin: 0 0 0 0 !important;
// }
// #vTabs-panel-VIRTUAL .ant-tabs-top > .ant-tabs-nav {
//   margin: 0 0 0 0 !important;
// }
// #vSnapChatNew .ant-card-head {
//   border-bottom: none !important;
// }
// #vSnapChatNew .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
//   justify-content: center !important;
// }
// #vSnapChatNew .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
//   font-size: 14px !important;
//   padding: 13px 0 !important;
// }
// .ant-input:hover{
//   // border-color: transparent !important;
// }
// #vTabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
//   display: none !important;
// }
// #vTabs .ant-tabs-content-holder {
//   width: 100% !important;
//   max-height: calc(100vh - 126px) !important;
//   overflow: overlay !important;
// }
// #vTabs .ant-tabs-content-holder:hover::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }
// #vTabs1 .ant-tabs-content-holder {
//   -ms-overflow-style: none !important; /* Internet Explorer 10+ */
//   scrollbar-width: none !important; /* Firefox */
//   width: 100% !important;
//   max-height: 100% !important;
// }
// #vTabs1 .ant-tabs-content-holder::-webkit-scrollbar {
//   display: none !important; /* Safari and Chrome*/
//   width: 0 !important;
//   height: 0 !important;
// }
#realPortfolio {
  max-width: 1450px;
  margin: auto !important;
  .ant-tabs-nav-list > .ant-tabs-tab {
    justify-content: center;
    margin: 0px !important;
    padding: 0px 20px 10px;
    width: 100% !important;
    border-right: 1px solid $green !important;
  }
  // .ant-tabs-tab:first-child {
  //   border-right: none !important;
  // }
}
#realPortfolio
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background: transparent !important;
  border: 0px !important;
  border-right: 1px solid $green !important;
}
#realPortfolio
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab
  :first-child {
  border-left: none !important;
}
#realPortfolio
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > button.ant-tabs-nav-add {
  background: transparent !important;
  color: $green !important;
  border: 0px !important;
  padding-bottom: 10px;
  // border-left: 1px solid $green !important;
}

#realPortfolio {
  #portfolioMovement {
    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
      border: 0px !important;
    }
    .ant-tabs-nav
      > .ant-tabs-nav-wrap
      > .ant-tabs-nav-list
      > .ant-tabs-tab:first-child {
      border-right: 1px solid $green !important;
    }
  }
  #stocks {
    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
      border: 0px !important;
    }
  }
}
#portfolioMovement {
  .ant-tabs-nav {
    margin-top: 0px !important;
  }
  .ant-tabs-nav-list {
    width: 100% !important;
    display: flex !important;
    // border: 1px solid red !important;
    justify-content: space-around !important;

    .ant-tabs-tab {
      margin: 0px !important;
      width: 100% !important;
      padding-top: 10px;
      //border: 1px solid red !important;
      justify-content: center;
    }
    .ant-tabs-tab:first-child {
      border-right: 1px solid $green !important;
    }
  }
}

// #vSnapChatNew .ant-tabs-content-holder {
//   height: auto !important;
// }
#vTabsSearchNew .ant-tabs-tab .anticon {
  margin-right: 10px !important;
}
#vTabsSearchNew .ant-tabs-tab {
  margin-right: 0 !important;
  font-size: 13px !important;
}
#vTabsSearchNew-tab-1 {
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 16px;
}
#vTabsSearchNew-tab-2 {
  margin: 0 0 0 15px;
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 16px;
}
#vTabsSearchNew-tab-2.ant-tabs-tab .anticon {
  font-size: 18px !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--scroll);
  // margin: 0 5px 5px 0;
}
::-webkit-scrollbar-track {
  background-color: var(--inputbgm);
  border-radius: 10px;
  // margin: 0 5px 5px 0;
}
::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
  // background: #F00;
}
body::-webkit-scrollbar:hover {
  width: 8px;
  height: 8px;
}
#vPortSide .ant-card-body:hover::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#sort-form {
  text-align: center;
  .ant-form-item {
    width: 100%;
  }
  .ant-col {
    .ant-form-item-control {
      text-align: left;
    }
  }
  .ant-col-11 {
    max-width: calc(45.83333333% - 15px);
    margin-right: 15px;
    // max-width: 115px !important;
    // .ant-select {

    // }
  }
  .ant-form-horizontal .ant-form-item-control {
    text-align: center;
  }
  button {
    margin: 5px 10px;
  }
  .anticon-minus-circle svg {
    color: var(--whitecolor) !important;
  }
}
#symbol-filter-form {
  label {
    color: var(--whitecolor) !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // background: var(--inputwhite) !important;
    background: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
    input {
      border: none !important;
    }
  }
  .ant-select-multiple .ant-select-selector {
    background: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
}
#institution-search-form,
#sector-filter-form {
  label {
    color: var(--whitecolor) !important;
  }
  .ant-select-multiple .ant-select-selector {
    background: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
  .ant-input-number {
    background: var(--whiteborder) !important;
    border: 1px solid var(--grayborder) !important;
  }
  .ant-input-number-input,
  .ant-input {
    background: none !important;
    border: none !important;
  }
}
.rc-virtual-list-scrollbar {
  display: block !important;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: $white !important;
}
.character-block {
  height: 100vh;
  scroll-snap-align: start;
}
#vFillerSortSideDiv .ant-drawer-content-wrapper {
  width: 450px !important;
}
#vPortSide .ant-card-extra {
  width: 56% !important;
}
.ant-tag-close-icon {
  color: $white !important;
}
#vPanelChange .ant-collapse-content {
  // background: $table_bg_color !important;
  background: var(--background) !important;
}
#vColumnVisibilityBlock .ant-drawer-content-wrapper {
  width: 450px !important;
}
#sign-up {
  .ant-form-item {
    margin-bottom: 24px !important;
  }
  .ant-input-affix-wrapper {
    background: transparent !important;
    border: 1px solid #eaeaea !important;
    border-radius: 5px;
    input {
      padding: 10px !important;
      color: $white !important;
    }
  }
  .ant-input-affix-wrapper:hover {
    border-color: #eaeaea !important;
  }
  .ant-col {
    position: static;
  }
  .ant-form-item-explain {
    position: absolute;
    bottom: -25px;
    font-size: 12px;
  }
  .ant-form-item-with-help {
    position: relative;
  }
  .ant-row:nth-child(4) {
    .ant-form-item-explain {
      position: relative !important;
      bottom: -5px !important;
    }
  }
  .ant-checkbox-wrapper {
    span {
      color: $white;
    }
  }
  .ant-input-password-icon {
    color: $white !important;
  }
}
// .ant-tabs-top > .ant-tabs-nav{
//   margin-bottom: 0 !important;
// }
._hj_feedback_container
  ._hj-XVgG4__ExpandedWidget__outerContainer
  ._hj-2JN4b__ExpandedWidget__closeButton {
  border: 0 !important;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-popover-inner {
  background-color: #2b343d !important;
  // box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-popover-message {
  color: white !important;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-right-color: #2b343d !important;
  border-bottom-color: #2b343d !important;
}
.ant-drawer-body:hover::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  border: 0px !important;
}
#SettingBoxTabs {
  min-height: calc(100vh - 91px);
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
#settingTab {
  margin-bottom: 30px;
  padding: 100px 20px 0;
}

#transaction_history {
  color: var(--whitecolor) !important;
  border-bottom: 1px solid var(--bordertransaction) !important;
  padding-left: 5px;
  padding-right: 5px;
  font-family: sans-serif;
  &:hover {
    background: var(--transaction);
  }
}

// #settingTab .ant-tabs-nav{
//   // margin: 100px 0 0 70px;
// }
// #settingTab .ant-tabs-nav{
//   // margin: 100px 0 0 70px;
// }
// #settingTab-panel-1 .ant-upload-picture-card-wrapper{
//   display: flex !important;
//   justify-content: center !important
// }
// #settingTab-panel-1 .ant-upload.ant-upload-select-picture-card{
//   border-radius: 50% !important;
//   //float: right !important;
//   margin: 60px 0px 20px 20px !important;
//   overflow: hidden;
//   display:  block !important;
//   height: 200px  !important;
//   width: 200px  !important;
//   margin: 0 0 20px 20px !important;
// }
// #settingTab-panel-1 .ant-upload.ant-upload-select-picture-card > .ant-upload{
//   padding: 0 !important;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
// }
// #settingTab-panel-1 .ant-upload.ant-upload-select-P > .ant-upload img{
//   border-radius: 50% !important;
//   display: block !important;
//   height: 100% !important;
//   object-fit: cover !important;
// }
// #settingTab .ant-tabs-content-holder{
//   margin: 0px 0 0px -1px !important;
// }
// #vAvtorNew .ant-avatar{
//   margin-right: 10px !important;
// }
#membership-form {
  #membership-form_cardHolderName,
  #membership-form_address,
  #membership-form_state,
  #membership-form_city,
  .ant-select-selector {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
    border: 1px solid var(--grayborder) !important;
    box-shadow: none !important;
  }
  .StripeElement {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
  }
}
#donation-form {
  .StripeElement {
    background-color: var(--whiteborder) !important;
    color: var(--whitecolor) !important;
  }
}
.CardField .CardBrandIcon-inner--frontsvg {
  stroke: white !important;
}
.CardField .CardBrandIcon svg {
  stroke: white !important;
}

.recharts-wrapper {
  cursor: pointer !important;
}

@media screen and (max-width: 767px) {
  #contactUs-form {
    margin: 20px 0 0 0;
    .ant-form-item {
      padding: 0 0;
    }
    .ant-radio-wrapper {
      margin-right: 0;
    }
  }
  .ant-menu-horizontal {
    > .ant-menu-submenu {
      > .ant-menu-submenu-title {
        font-size: 40px !important;
        line-height: 50px;
      }
    }
  }
  .ant-drawer-right.ant-drawer-open {
    width: 100% !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0 !important;
  }
  // #vSearch .ant-space-align-center {
  //   flex-direction: column !important;
  //   gap: 20px !important;
  // }
  // #vSearch .ant-space-item {
  //   width: 100% !important;
  //   margin-right: 0 !important;
  // }
  // #vSearch .ant-space-align-center :nth-child(3) {
  //   order: 2 !important;
  // }
  // #vSearch .ant-space-align-center :nth-child(2) {
  //   order: 3 !important;
  // }
  #portfolioSnapshot .antd-pro-charts-pie-pie {
    height: 100% !important;
  }
  #portfolioSnapshot-sector .antd-pro-charts-pie-pie {
    height: auto !important;
  }
  #portfolioSnapshot-sector
    .antd-pro-charts-pie-pie
    .antd-pro-charts-pie-legend {
    margin: 0 0 0 0 !important;
    position: relative !important;
    transform: translateY(0%) !important;
  }
  #portfolioSnapshot-sector .antd-pro-charts-pie-legend li {
    display: flex !important;
    flex-basis: 100% !important;
    margin: 0 0 3% 0 !important;
    justify-content: center !important;
  }
  #portfolioSnapshot-sector
    .antd-pro-charts-pie-pie
    .antd-pro-charts-pie-legendTitle {
    width: 60% !important;
  }
  .antd-pro-charts-pie-pie .antd-pro-charts-pie-legend {
    min-width: 100% !important;
  }
  #vTabsSearchNew.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    display: none !important;
  }
  #portfolioSnapshot-symbol .antd-pro-charts-pie-pie {
    height: auto !important;
  }
  #portfolioSnapshot-symbol .antd-pro-charts-pie-legend li {
    flex-basis: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
  #portfolioSnapshot-symbol
    .antd-pro-charts-pie-pie
    .antd-pro-charts-pie-legend {
    margin: 0 0 0 0 !important;
    position: relative !important;
    transform: translateY(0%) !important;
  }
  #portfolioSnapshot-sector
    .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend
    .antd-pro-charts-pie-chart {
    margin: 0 auto !important;
  }
  #portfolioSnapshot-symbol
    .antd-pro-charts-pie-pie.antd-pro-charts-pie-hasLegend
    .antd-pro-charts-pie-chart {
    margin: 0 auto !important;
  }
  #sort-form {
    .ant-col-11 {
      max-width: calc(45.83333333% - 10px);
      margin-right: 10px;
      // max-width: 115px !important;
      // .ant-select {

      // }
    }
  }
  #vFillerSortSideDiv .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  // #vTabs.ant-tabs-top > .ant-tabs-nav {
  //   margin: 0 auto !important;
  //   width: 100% !important;
  // }
  // #vTabs .ant-tabs-content-holder{
  //   // max-height: 100% !important;
  // }
  // #faqsTabsBox.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list{
  //   flex-direction: row !important;
  // }
  #vColumnVisibilityBlock .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .character-block {
    height: auto;
  }
  #SettingBoxTabs {
    width: 100%;
  }
  #settingTab.ant-tabs {
    display: block !important;
  }
  #settingTab .ant-tabs-nav {
    margin: 0 15px;
  }
  #settingTab.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    display: block !important;
    white-space: nowrap;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  #settingTab.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 20px 0 0 !important;
    width: auto !important;
  }
  #settingTab .ant-tabs-tab {
    color: var(--whitecolor) !important;
  }
  #settingTab.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 0px !important;
  }
  #settingTab-panel-2,
  #settingTab-panel-3,
  #settingTab-panel-1,
  #settingTab-panel-4 {
    padding-left: 0 !important;
  }
  // #settingTab-panel-1 .ant-upload.ant-upload-select-picture-card{
  //   width: 150px !important;
  //   height: 150px !important;
  //   margin: 0 0 20px 0 !important;
  // }
  #settingTab .ant-tabs-content-holder {
    margin: 0 0 !important;
    border: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  // #vSearch .ant-space-align-center {
  //   flex-direction: row !important;
  //   gap: 20px !important;
  //   align-items: baseline !important;
  //   flex-wrap: wrap;
  //   justify-content: center !important;
  // }
  // #vSearch .ant-space-align-center :nth-child(3) {
  //   order: 1 !important;
  // }
  // #vSearch .ant-space-align-center :nth-child(2) {
  //   order: 3 !important;
  // }
  #portfolioSnapshot-sector .antd-pro-charts-pie-legend li {
    flex-basis: 100% !important;
  }
  #portfolioSnapshot-symbol .antd-pro-charts-pie-legend li {
    flex-basis: 100% !important;
  }
  // #vTabs.ant-tabs-top > .ant-tabs-nav {
  //   margin: 0 0 !important;
  //   width: 100% !important;
  //   padding-top: 0 !important;
  // }
  // #vTabs .ant-tabs-content-holder {
  //   max-height: 100% !important;
  // }
  .character-block {
    height: auto;
  }
  #settingTab {
    padding-left: 20px;
  }
  // #settingTab .ant-tabs-nav {
  //   // margin: 100px 0 0 20px;
  // }
  // #settingTab-panel-1 .ant-upload.ant-upload-select-picture-card{
  //   width: 150px !important;
  //   height: 150px !important;
  //   margin: 0 0 20px 0 !important;
  // }
  // #settingTab-panel-1 .ant-upload-picture-card-wrapper{
  //   width: auto !important;
  // }
  #vFormId.ant-form {
    width: 100% !important;
  }
}
#portfolioMovement {
  .recharts-cartesian-axis {
    stroke: $green !important;
    display: none !important;
  }
  .recharts-cartesian-axis-ticks {
    display: none !important;
  }
}

@media (max-width: 525px) {
  #settingTab {
    .ant-tabs-content-holder {
      border-left: 0 !important;
    }
    .ant-tabs-tabpane.ant-tabs-tabpane-active {
      padding-left: 0 !important;
    }
  }
}
@media (min-width: 993px) and (max-width: 1273px) {
  #portfolioSnapshot-sector .antd-pro-charts-pie-legend li {
    flex-basis: 46% !important;
  }
  #portfolioSnapshot-symbol .antd-pro-charts-pie-legend li {
    flex-basis: 30% !important;
  }
}
@media (min-width: 1115px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 91px);
  }
}
@media (max-width: 1114px) and (min-width: 855px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 106px);
  }
}
@media (max-width: 854px) and (min-width: 768px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 121px);
  }
}
@media (max-width: 767px) and (min-width: 675px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 167px);
  }
}
@media (max-width: 674px) and (min-width: 575px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 182px);
  }
}
@media (max-width: 574px) and (min-width: 488px) {
  #SettingBoxTabs {
    min-height: calc(100vh - 197px);
  }
}
