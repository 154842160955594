/* Notification container - centered on the screen */
.notification-container {
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 800px;
    max-width: 100%;
    height: 1000px !important; /* Increased height for the notification */
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto; /* Ensures the content scrolls if it exceeds the height */
}
@media (max-width: 767px) {
    .hide-on-mobile {
        display: none !important;
    }
}

/* Notification styles */
.notification {
    position: relative;
    margin-top: 100px !important;
    background-color: var(--background-color);
    color: #1abd50;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid var(--whitecolor);
    height:400px !important;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: opacity 0.5s ease-out;
    white-space: pre-line;
}

.notification p {
    margin: 0;
    padding-right: 10px;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    color: var(--whitecolor);
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
}

.close-btn:hover {
    color: #ff0000;
}

/* Badge styles */
.badge {
    position: fixed;
    background-color: #1abd50; /* Green background */
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    z-index: 9999;
    transition: top 0.1s, left 0.1s;
    bottom: 20px; /* Position it at the bottom-right initially */
    right: 20px;
}

/* Cloud-like icon with text */
.cloud-icon {
    position: absolute;
    top: -15px;
    left: -15px;
    background-color: #fff; /* White background for cloud */
    border-radius: 50%;
    padding: 5px 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: giggle 0.6s infinite alternate; /* Apply giggle animation */
}

/* Cloud text style */
.cloud-text {
    font-size: 12px;
    color: #1abd50; /* Green text */
    font-weight: bold;
}

/* Keyframe animation for the giggle effect */
@keyframes giggle {
    0% {
        transform: rotate(0deg); /* No rotation */
    }
    25% {
        transform: rotate(5deg); /* Rotate slightly right */
    }
    50% {
        transform: rotate(-5deg); /* Rotate slightly left */
    }
    75% {
        transform: rotate(3deg); /* Rotate right */
    }
    100% {
        transform: rotate(-3deg); /* Rotate left */
    }
}

/* Notification icon inside the badge */
.notification-icon {
    font-size: 24px;
    color: #fff !important; /* Ensure icon stays white */
}
