
/* General body styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

/* News container */
.news-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

/* News card styling */
.news-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out;
}

.news-card:hover {
    transform: translateY(-5px);
}

/* Left section for image */
.news-card-left {
    flex: 1;
    padding: 10px;
}

/* Set a fixed height and width for the image */
.news-image {
    width: 100%;
    height: 200px; /* Set fixed height */
    object-fit: cover; /* This will ensure the image covers the area while maintaining its aspect ratio */
    border-radius: 8px;
}

/* Right section for text content */
.news-card-right {
    flex: 2;
    padding: 15px;
}

.news-headline {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    font-weight: bold;
}

.news-summary {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
}

.news-source {
    font-size: 0.9rem;
    color: #888;
    margin-top: 10px;
}

.news-link {
    font-size: 1rem;
    color: #007bff;
    text-decoration: none;
    margin-top: 15px;
    display: inline-block;
}

.news-link:hover {
    text-decoration: underline;
}
.teslaInfoTable {
    width: 40%;
    margin: auto;
    border-collapse: collapse;
    background-color: #fff;
}

.tesla-info-caption {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.teslaInfoTable th, .teslaInfoTable td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.teslaInfoTable th {
    background-color: #2FC058;
    color: var(--whitecolor);
    font-size: 18px;
}

.teslaInfoTable td {
    font-size: 16px;
    background-color: var(--whitecolor);
    color: var(--grayborder);
}

.teslaInfoTable td:last-child {
    text-align: center;
}

.teslaInfoTable tr:hover {
    background-color: #f1f1f1;
}

