$black: #000000;
$white: #ffffff;
$blue: #054fff;
$green: #1ABD50;
$red: #f94312;
$black1: #495057;
$black2: #000034;
$orange: #f5a623;
$grey: #727272;
$grey2: #e8e8e8;
$lightGrey: #dcdde1;
$lightBlue :#ebf1ff;
$garyF2: #F2F2F2;
$lightBlue: #90b5e8;
$grey3: #74788D;
$lightBlue2: #EFF2F7;
$pink: #ff3366;
$pinkBorder: #e50039;

// Dark Mode colors;

$dark_primary: #2B343D;
$dark_secondary: #21272C;
$input_bg: #46515C;
$input_text_color: #D8D8D8;
$dark_primary_btn1: #46515C;
$breadcrum_text: #D8D8D8;
$green_text_color: #2FC058;
$collapse_header_color: #D8D8D8;
$collapse_text_color: #D8D8D8;
$collapse_box_bg_color: #2B343D;
$table_header_bg_color: #46515C;
$table_header_text_color: #D8D8D8;
$table_header_border_color: #2B343D;
$table_body_bg_color: #363E46;
$list_text_color: #D8D8D8;
$icon_dropdown_color: #B7B7B7;
$search_fulldetails_h2_color: #2FC058;
$common_text_color: #D8D8D8;
$date_input_picker_bg: #46515C;
$date_picker_btn: #D8D8D8;
$date_picker_disabled_bg: #D8D8D8;
$date_picker_disabled_color: #46515C;
$orange_dark_theme_color: #E9A126;
$collapse_border: #46515C;
$common_border: #46515C;
$green_button_bg: #2FC058;
$table_bg_color: #21272c;
$yellow : #fef001;
