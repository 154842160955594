
.navigation-list {
    position: fixed;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    /* left: -10px; */
    list-style: none;
}

.navigation-list__item {
    transition: .3s;
    cursor: pointer;
    border-left: 1.1px solid #1abd50;
    height: 40px;
    margin-bottom: 20px;
}

.navigation-list__item--active {
    border-left: 5px solid #1abd50;
}

@media (max-width: 767px) {
    .navigation-list {
        display: none;
        opacity: 0;
        position: static;
    }
}
